import React, { useEffect, useState } from "react";
import requestApplicationJson from "../../../../utils/fetch";
import { useSelector } from "react-redux";
import { toast } from "react-toastify"; // 1. Import toast from react-toastify
import Table from "react-bootstrap/Table";
import { Pencil, Plus } from "react-bootstrap-icons";
import CustomPagination from "../../../Entities/Pagination";

function ProductCategory() {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [TotalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [addDialogClicked, setAddDialogClicked] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const userSession = useSelector((state) => state.auth);
  const handleEditButtonClick = (record) => {
    setCategoryId(record.id);
    setCategoryName(record.name);
    setAddDialogClicked(true);
  };
  const handleActivateDeactivate = async (tableName,curStatus,record_id)=>{
    let activateDeactivatePayload = {"record_meta":tableName,"record_id":record_id,"status":!Boolean(curStatus)}
    let response = await requestApplicationJson("/admin/AdminCommon/activateDeactivateRecord","POST",activateDeactivatePayload,userSession);
    if(response.status)
    {
      toast.success(response.data)
      handleTablePaginationAndSearch(currentPage,pageLength,searchKeyword)
    }
    else{
      toast.error(response.data)
    }
  }
  const handleTablePaginationAndSearch = async (pageNumber, pageLength, search) => {
    let payload = { page: pageNumber, pagelength: pageLength, search: search,"status":0 };
    let apiResponse = await requestApplicationJson("/admin/productAttr/getCategories", "POST", payload, userSession);
    if (apiResponse.status) {
      setTableData(apiResponse.data.records);
      SetTotalRecords(apiResponse.data.total);
      setTotalPages(apiResponse.data.total / pageLength > 1 ? Math.ceil(apiResponse.data.total / pageLength) : 1);
    }
  };
  useEffect(() => {
    handleTablePaginationAndSearch(currentPage, pageLength, "");
  }, []);
  const handleSaveRecord = async () => {
    let payload = {};
    let categoryUrl = "";
    console.log(categoryId);
    if (categoryId !== null) {
      payload = { category_name: categoryName, category_id: categoryId };
      categoryUrl = "/admin/productAttr/updateCategory";
    } else {
      payload = { category_name: categoryName };
      categoryUrl = "/admin/productAttr/createCategory";
    }
    let addEditResponse = await requestApplicationJson(categoryUrl, "POST", payload, userSession);
    if (addEditResponse.status) {
      setCategoryId(null);
      setCategoryName("");
      setAddDialogClicked(false);
      toast.success(addEditResponse.data.message);
      handleTablePaginationAndSearch(currentPage, pageLength, "");
    } else {
      toast.error(addEditResponse.data.message);
    }
  };
  return (
    <div
      className="col-md-12"
      onLoad={() => {
        handleTablePaginationAndSearch(currentPage, pageLength, "");
      }}
    >
      <div className="row">
        {addDialogClicked && (
          <div className="col-md-12 mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Enter Category Name</label>
                <input
                  className="form-control"
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                  value={categoryName}
                  placeholder="Category name"
                  maxLength={100}
                ></input>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    if (categoryName !== "") {
                      handleSaveRecord();
                    } else {
                      toast.error("Category name Can't be empty.");
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12 table-search-and-add-button-class">
          <input
            placeholder="Search With Category name"
            className="form-control"
            onChange={(e) => {
              setSearchKeyword(e.target.value);
              if (e.target.value.length > 3 || e.target.value === "") {
                handleTablePaginationAndSearch(currentPage, pageLength, e.target.value);
              }
              console.log(e.target.value);
            }}
          ></input>
          <button
            className="btn btn-info"
            onClick={() => {
              setAddDialogClicked(!addDialogClicked);
            }}
          >
            <Plus></Plus>
          </button>
        </div>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Category Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((record) => {
              return (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.name}</td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        handleEditButtonClick(record);
                      }}
                    >
                      <Pencil></Pencil>
                    </button>
                    <button onClick={()=>{
                      handleActivateDeactivate('cHJvZHVjdF9jYXRlZ29yaWVz',record.status,record.id)
                    }} style={{marginLeft:"10px"}} className={record.status === 1? "ml-1 badge bg-danger":"badge bg-success"}> 
                    {record.status === 1? "Deactivate":"Activate"}
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="row">
        <div className="col-md-12">
          <CustomPagination
            currentPage={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
              handleTablePaginationAndSearch(page, pageLength, searchKeyword);
            }}
            totalPages={TotalPages}
          ></CustomPagination>
        </div>
      </div>
    </div>
  );
}
export default ProductCategory;
