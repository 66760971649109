import React, { useRef } from "react";
import {
  House,
  Grid,
  File,
  CreditCard,
  Stopwatch,
  CheckCircle,
  Basket,
  Truck,
  PersonVcard,
  Person,
  Receipt,
  BoxArrowRight,
  Sliders2,
  Shop,
  Gear
} from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { revertAuth } from "../../sessions/docks/authSlice";
import { Link, useNavigate } from "react-router-dom";
import commonFunc from '../../utils/commonFunc'
  const SideDrawer = ({ isOpen, toggleSideDrawer }) => {
  const sideDrawerRef = useRef(null);
  const userSession = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstName = userSession.first_name ? commonFunc.capitalizeFirstLetter(userSession.first_name) : '';
  const lastName = userSession.last_name ? commonFunc.capitalizeFirstLetter(userSession.last_name) : '';
  return (
    <div className={`side-drawer ${isOpen ? "open" : ""}`} ref={sideDrawerRef}>
      {isOpen && (
        <nav className="hc-offcanvas-nav">
          <div className="nav-container">
            <div className="nav-wrapper nav-wrapper-0">
              <div className="nav-content">
                <ul className="second-nav">
                  <li className="osahan-user-profile bg-primary">
                    <div className="nav-item-wrapper">
                      <span className="nav-item">
                        <div className="d-flex align-items-center gap-2">
                          <img src="../../img/delivery-boy.jpg" alt="" className="rounded-pill img-fluid" />
                          <div className="ps-1">
                            <h5 className="fw-bold text-white osahan-mb-1">{firstName || lastName ? `${firstName} ${lastName}` : 'USER'}</h5>
                            <p className="text-white-50 m-0">{userSession.user_type}</p>
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  {userSession.user_type==="ADMIN"?
                    <>
                  <li>
                    <div className="nav-item-wrapper">
                      <Link to="/adminPanel/home">
                        <House className="me-2" color="#343a40" size={15} />
                        Dashboard
                      </Link>
                    </div>
                  </li>   
                  <li>
                    <div className="nav-item-wrapper">
                      <Link to={"/adminPanel/AddProducts"} className="nav-item" href="javascript:void(0)">
                        <Shop className="me-2" color="#343a40" size={15} />
                        Product
                      </Link>
                    </div>
                  </li>                
                  <li>
                    <div className="nav-item-wrapper">
                      <Link to={"/adminPanel/ProductsSettings"} className="nav-item" href="listing.html">
                        <Gear className="me-2" color="#343a40" size={15} />
                        Product Settings
                      </Link>
                    </div>
                  </li>  
                  <li>
                    <div className="nav-item-wrapper">
                      <a
                        className="nav-item"
                        href="javascript:void(0)"
                        onClick={() => {
                          handleLogout("Admin");
                        }}
                      ><BoxArrowRight className="me-2" color="#343a40" size={15} />
                        Sign Out
                      </a>
                    </div>
                  </li>                 
                    </>:<>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="listing.html">
                        <Grid className="me-2" color="#343a40" size={15} />
                        Listing
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="product-detail.html">
                        <File className="me-2" color="#343a40" size={15} />
                        Product Detail
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="cart.html">
                        <Basket className="me-2" color="#343a40" size={15} />
                        Cart
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="payment-option.html">
                        <CreditCard className="me-2" color="#343a40" size={15} />
                        Payment Option
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="payment-details.html">
                        <Stopwatch className="me-2" color="#343a40" size={15} />
                        Payment Time
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="order-received.html">
                        <CheckCircle className="me-2" color="#343a40" size={15} />
                        Order Received
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="track-order.html">
                        <Truck className="me-2" color="#343a40" size={15} />
                        Track Order
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="track-delivery-boy.html">
                        <PersonVcard className="me-2" color="#343a40" size={15} />
                        Track Delivery Boy
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="profile.html">
                        <Person className="me-2" color="#343a40" size={15} />
                        Profile
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a className="nav-item" href="order-detail.html">
                        <Receipt className="me-2" color="#343a40" size={15} />
                        Order Details
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="nav-item-wrapper">
                      <a
                        className="nav-item"
                        href="javascript:void(0)"
                        onClick={() => {
                          handleLogout("Customer");
                        }}
                      >
                        <BoxArrowRight className="me-2" color="#343a40" size={15} />
                        Sign Out
                      </a>
                    </div>
                  </li>
                  </>  
                }
                </ul>
                {userSession.user_type==="ADMIN"?
                  <>
                <ul className="bottom-nav">
                  <li className="home">
                    <a href="javascript:void(0)" className="text-primary" onClick={toggleSideDrawer}>
                      <p className="h5 m-0">
                        <House className="me-2" color="#343a40" size={15} />
                      </p>
                      Dashboard
                    </a>
                    </li>
                  <li className="profile">
                    <a href="profile.html">
                      <p className="h5 m-0">
                        <Person className="me-2" color="#343a40" size={15} />
                      </p>
                      Profile
                    </a>
                  </li>
                </ul></>:<>
                <ul className="bottom-nav">
                  <li className="home">
                    <a href="javascript:void(0)" className="text-primary" onClick={toggleSideDrawer}>
                      <p className="h5 m-0">
                        <House className="me-2" color="#343a40" size={15} />
                      </p>
                      Home
                    </a>
                  </li>
                  <li className="cart">
                    <a href="cart.html">
                      <p className="h5 m-0">
                        <Basket className="me-2" color="#343a40" size={15} />
                      </p>
                      Cart
                    </a>
                  </li>
                  <li className="profile">
                    <a href="profile.html">
                      <p className="h5 m-0">
                        <Person className="me-2" color="#343a40" size={15} />
                      </p>
                      Profile
                    </a>
                  </li>
                </ul>
                </>                
                }
              </div>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
  function handleLogout(user) {
    if (userSession.isLoggedIn) {
      let route = user == "Admin" ? "/adminPanel" : "/login";
      dispatch(revertAuth());
      navigate(route);
    }
  }
};

export default SideDrawer;
