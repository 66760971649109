 function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  }
 function isValidIndianNumber(phoneNumber) {
    // Combine mobile and landline regex patterns
    const mobileRegex = /^(\+?\d{1,2}[- ]?)?\d{10}$/;
    const landlineRegex = /^(\+?[\d -]?)?\(?\d{2}\)?[\d -]{7,10}$/;
  
    // Check if either mobile or landline format matches
    return mobileRegex.test(phoneNumber) || landlineRegex.test(phoneNumber);
  }

  export default {isValidEmail,isValidIndianNumber}