import React, { useState, useEffect } from 'react'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { List, Person } from 'react-bootstrap-icons';
import Sidebar from "../Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { revertAuth } from "../../sessions/docks/authSlice";

function Header() {
    
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
    const userSession = useSelector((state) => state.auth);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Update every second

        return () => clearInterval(interval);
    }, []);    
    const navigate = useNavigate();
    const toggleSideDrawer = () => {
        setIsSideDrawerOpen(!isSideDrawerOpen);
    };
    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };;
        return date.toLocaleString('en-US', options);
    };

    const formatTime = (date) => {
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };
        return date.toLocaleString('en-US', options);
    };
    

    return (
        <>
            <Sidebar isOpen={isSideDrawerOpen} toggleSideDrawer={toggleSideDrawer} />
            <div className="homepage-navbar bg-light shadow mb-auto p-3 bg-primary">
                <div className="d-flex align-items-center">
                    <a href="javascript:void(0)" className="link-dark text-truncate d-flex align-items-center gap-2" data-bs-toggle="offcanvas" data-bs-target="#location" aria-controls="location">
                        <i className="icofont-clock-time fs-2 text-white"></i>
                        <span>
                            {userSession.user_type == "ADMIN" ?
                                <>
                                    <h6 className="fw-bold text-white mb-0">{formatDate(currentDateTime)}</h6>
                                    <p className="text-white-50 text-truncate d-inline-block mb-0 w-75 align-bottom">{formatTime(currentDateTime)}</p>
                                </> :
                                <>
                                    <h6 className="fw-bold text-white mb-0">Delivery in 16 minutes</h6>
                                    <p className="text-white-50 text-truncate d-inline-block mb-0 w-75 align-bottom">H.No. 2834 Street, 784 Sector, Ludhiana, Punjab</p>
                                </>
                            }
                        </span>
                    </a>
                    <div className="d-flex align-items-center gap-2 ms-auto">
                        <Link to="/login" className="link-dark">
                            <div className="bg-dark bg-opacity-75 rounded-circle user-icon">
                                <Person color="white" size={21} />
                            </div>
                        </Link>
                        <a className="toggle" href="javascript:void(0)" onClick={toggleSideDrawer} >
                            <b className="bg-dark bg-opacity-75 rounded-circle user-icon">
                                <List color="white" size={21} />
                            </b>
                        </a>

                    </div>
                </div>
                <div className="pt-3">
                    {userSession.user_type != "ADMIN" ?
                    <>
                        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#searchoffcanvas" aria-controls="searchoffcanvas">
                            <div className="input-group bg-white rounded-3 shadow-sm py-1">
                                <input type="text" className="form-control bg-transparent border-0 rounded-0 px-3" placeholder="Search for atta, dal, coke and more" aria-label="Search for atta, dal, coke and more" aria-describedby="search" />
                                <span className="input-group-text bg-transparent border-0 rounded-0 pe-3" id="search"><i className="icofont-search-1"></i></span>
                            </div>
                        </a>
                    </>:<></>
                      }
                </div>
            </div>
        </>
    )
}

export default Header