import React, { useState } from "react";
import requestApplicationJson from "../../../utils/fetch";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "../../../sessions/docks/authSlice";
import { useNavigate } from "react-router-dom";
import "../../../css/login.css";
import { toast } from "react-toastify"; // 1. Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader/Loader";
import { Eye,EyeSlash } from "react-bootstrap-icons";

function AdminLogin() {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const userSession = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setUserEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleForgotPasswordClick = (param) => {
    param === 1 ? setForgotPasswordVisible(true) : setForgotPasswordVisible(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function handleForgotPassword(e) {
    e.preventDefault();
    // Your forgot password logic here
    console.log("Sending reset link to:", userEmail);
    // Example logic: Send reset link to userEmail
  }

  async function handleLogin(e) {
    e.preventDefault();
    const errors = {};

    // Validation for email
    if (!userEmail.trim()) {
      toast.error("Email is required");
      return;
    } else if (!/\S+@\S+\.\S+/.test(userEmail)) {
      toast.error("Email is invalid");
      return;
    }

    // Validation for password
    if (!password.trim()) {
      toast.error("Password is required");
      return;
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{7,})/.test(password)) {
      toast.error(
        "Password must contain at least 1 lowercase, 1 uppercase, 1 special character, and be at least 7 characters long"
      );
      return;
    }

    // If there are errors, set them and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setLoading(true);
    let payload = {
      username: userEmail,
      password: password,
    };
    let url = "/auth/admin/login";
    let response = await requestApplicationJson(url, "POST", payload, userSession);
    setLoading(false);
    if (response.status) {
      let initial_slice = { ...userSession, ...response.data.user };
      console.log(initial_slice);
      initial_slice.tokenJWT = response.data.token;
      initial_slice.isLoggedIn = true;
      dispatch(setAuthToken(initial_slice));
      toast.success(response.data.message);
      if (initial_slice.user_type === "ADMIN") {
        navigate("/adminPanel/home");
      }
    } else {
      toast.error(response.data.message);
    }
    console.log(response);
  }
  const myImportantStyle = {
    width: '85% !important', // Width with !important
  };
  return (
    <>
      <Loader isLoading={loading} />
      <div className="container">
        <div className="screen">
          <div className="screen__content">
            <div className="wrapper fadeInDown">
              <div id="formContent">
                <h2 className="active"> Master Admin </h2>
                <div className="fadeIn first">
                  <img src="../img/userlogin.svg" id="icon" alt="User Icon" />
                </div>
                {!forgotPasswordVisible && (
                  <>
                <form onSubmit={handleLogin}>
                  <input onChange={handleEmailChange} type="text" id="login" className="fadeIn second" name="login" placeholder="Enter Your Email" style={{width:'85% !important'}} />
                  <input onChange={handlePasswordChange} type={showPassword ? 'text' : 'password'} id="password" className="fadeIn third" name="login" placeholder="Enter Your Password" style={myImportantStyle}  />
                  {password && (
                  <span style={{marginLeft:"-33px",marginTop:"23px",position:"absolute",cursor:"pointer"}} onClick={togglePasswordVisibility}>
                  {showPassword ?
                  <>
                   <EyeSlash className="me-2" color="#343a40" size={20} />
                  </>:
                   <Eye className="me-2" color="#343a40" size={20} />
                  }
                  </span>
                  )}
                  <input type="submit" className="fadeIn fourth" value="Log In" />
                </form>
                <div id="formFooter">
                  <a className="underlineHover" href="javascript:void(0)" onClick={() => handleForgotPasswordClick(1)}>Forgot Password?</a>
                </div>
                </>
                  )}
                  {forgotPasswordVisible && (
                  <>
                <form onSubmit={handleForgotPassword}>
                  <input onChange={handleEmailChange} type="text" id="login" className="fadeIn second" name="login" placeholder="Enter Your Email" style={myImportantStyle} />
                  <input type="submit" className="fadeIn fourth" value="send Reset Link" />
                </form>
                <div id="formFooter">
                  <a className="underlineHover" href="javascript:void(0)" onClick={() => handleForgotPasswordClick(2)}>Back to Login?</a>
                </div>
                </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
