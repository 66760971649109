import React, { useState } from "react";
import requestApplicationJson from "../../../utils/fetch";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../css/login.css";
import { toast } from "react-toastify"; // 1. Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader/Loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProductCategory from "./SettingsUnits/ProductCategory";
import ProductSubCategory from "./SettingsUnits/ProductSubCategory";
import "./ProductSettings.css";
import ProductUnitSettings from "./SettingsUnits/ProductUnitSettings";
import ProductBrandSettings from "./SettingsUnits/ProductBrandSettings";
import Header from '../../../components/Header/Header';

function ProductSettings() {
  const [currentSettingScreen, setCurrentSettingScreen] = useState("Category");
  const [loading, setLoading] = useState(false);
  const userSession = useSelector((state) => state.auth);
  return (
    <>
        <Header/>
      <Loader isLoading={loading} />
      <div className="container-fluid">
        <div className="row">
          <Tabs
            defaultActiveKey={currentSettingScreen}
            className="mb-3"
            onSelect={(k) => {
              setCurrentSettingScreen(k);
            }}
          >
            <Tab eventKey="Category" title="Category">
              {currentSettingScreen === "Category" ? <ProductCategory></ProductCategory> : <></>}
            </Tab>
            <Tab eventKey="Sub-Category" title="Sub-Category">
              {currentSettingScreen === "Sub-Category" ? <ProductSubCategory></ProductSubCategory> : <></>}
            </Tab>
            <Tab eventKey="Product-Unit" title="Product-Unit">
              {currentSettingScreen === "Product-Unit" ? <ProductUnitSettings></ProductUnitSettings> : <></>}
            </Tab>
            <Tab eventKey="Product-Brand" title="Product-Brand">
              {currentSettingScreen === "Product-Brand" ? <ProductBrandSettings></ProductBrandSettings> : <></>}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default ProductSettings;
