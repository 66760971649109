import React,{useState} from 'react'
import { House, Shop, Geo, Basket, Person } from 'react-bootstrap-icons';


function Footer() {

    return (
        <>
            <div className="footer bg-white shadow mt-auto border-top fixed">
                <div className="d-flex align-items-center justify-content-between py-1">
                    <a href="index.html" className="link-dark text-center col py-2 p-1">
                        <House className='h3 m-0' color="#343a40" size={25} />
                        <p className="small m-0 pt-1">Home</p>
                    </a>
                    <a href="listing.html" className="text-muted text-center col py-2 p-1">
                        <Shop className='h3 m-0' color="#343a40" size={25} />
                        <p className="small m-0 pt-1">Shop</p>
                    </a>
                    <a href="track-delivery-boy.html" className="text-muted text-center col py-2 p-1">
                        <Geo className='h3 m-0' color="#343a40" size={25} />
                        <p className="small m-0 pt-1">Track</p>
                    </a>
                    <a href="cart.html" className="text-muted text-center col py-2 p-1">
                        <Basket className='h3 m-0' color="#343a40" size={25} />
                        <p className="small m-0 pt-1">Cart</p>
                    </a>
                    <a href="profile.html" className="text-muted text-center col py-2 p-1">
                        <Person className='h3 m-0' color="#343a40" size={25} />
                        <p className="small m-0 pt-1">Profile</p>
                    </a>
                </div>
            </div>
            <div className="offcanvas offcanvas-end border-0" tabIndex="-1" id="location" aria-labelledby="locationLabel">
                <div className="offcanvas-header bg-primary d-flex align-items-center justify-content-start gap-3">
                    <a href="javascript:void(0)" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-arrow-left fs-5 text-white"></i></a>
                    <h6 className="offcanvas-title text-white m-0" id="locationLabel">Enter your area or apartment name</h6>
                </div>
                <div className="offcanvas-body">

                    <div className="input-group bg-white rounded-3 border py-1">
                        <a href="javascript:void(0)" className="input-group-text bg-transparent border-0 rounded-0 px-3" id="search"><i className="icofont-search"></i></a>
                        <input type="text" className="form-control bg-transparent border-0 rounded-0 ps-0" placeholder="Try J P Nagar, Andheri West etc.." aria-label="Try J P Nagar, Andheri West etc.." aria-describedby="search" />
                    </div>
                    <a href="javascript:void(0)" className="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                        <div className="d-flex align-items-center gap-2 text-primary py-3 border-bottom">
                            <i className="icofont-location-arrow"></i>
                            <p className="m-0">Use my Current location</p>
                        </div>
                    </a>

                    <div className="border-bottom py-3">
                        <p className="text-black text-uppercase small">Saved Addresses</p>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="w-75">
                                <a href="javascript:void(0)" className="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                                    <div className="d-flex align-items-center gap-2 osahan-mb-1">
                                        <i className="icofont-ui-home text-muted fs-6"></i>
                                        <h6 className="fw-bold mb-0">Home</h6>
                                    </div>
                                    <p className="text-muted text-truncate mb-0 ps-4">H.No. 2834 Street, 784 Sector, Ludhiana, Punjab</p>
                                </a>
                            </div>
                            <a href="javascript:void(0)" className="link-dark">
                                <div className="bg-light rounded-circle icon-sm">
                                    <i className="icofont-share fs-5 text-dark-emphasis"></i>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="py-3">
                        <p className="text-black text-uppercase small">Recent Searches</p>
                        <a href="javascript:void(0)" className="link-dark" data-bs-dismiss="offcanvas" aria-label="Close">
                            <div className="d-flex align-items-center gap-2 osahan-mb-1">
                                <i className="icofont-location-pin text-muted fs-6"></i>
                                <h6 className="fw-bold mb-0">Ludhiana</h6>
                            </div>
                            <p className="text-muted text-truncate mb-0 ps-4">87997 Street, 784 Sector, Ludhiana, Punjab</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-bottom border-0 h-100" tabIndex="-1" id="searchoffcanvas" aria-labelledby="searchoffcanvasLabel">
                <div className="offcanvas-header bg-primary">
                    <div className="input-group bg-white rounded-3 border-0 p-0">
                        <a href="javascript:void(0)" className="input-group-text bg-transparent border-0 rounded-0" id="search" data-bs-dismiss="offcanvas" aria-label="Close"><i className="bi bi-arrow-left fs-5"></i></a>
                        <input type="text" className="form-control bg-transparent border-0 rounded-0" placeholder="Search for groceries and more" aria-label="Search for groceries and more" aria-describedby="search" value="Chips" />
                    </div>
                </div>
                <div className="offcanvas-body p-0">
                    <div className="row g-0">
                        <div className="col-12 border-bottom">
                            <h6 className="p-3 m-0">139 result for <span className="fw-bold">"Chips"</span></h6>
                        </div>
                    </div>
                    <div className="row border-bottom g-0">
                        <div className="col-6 border-end">
                            <div className="card bg-transparent border-0 p-3">
                                <a href="product-detail.html">
                                    <img src="img/uncle-chips.png" alt className="img-fluid w-75 d-block mx-auto" />
                                </a>
                                <div className="card-body p-0">
                                    <small className="text-muted">Uncle Chipps</small>
                                    <h6 className="card-title fw-bold">Spicy Treat Sweet Chipps</h6>
                                    <p className="card-text text-muted">50 g</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                                    <h6 className="fw-bold m-0">$20</h6>
                                    <a href="cart.html" className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card bg-transparent border-0 p-3">
                                <a href="product-detail.html">
                                    <img src="img/kurkure.png" alt className="img-fluid w-75 d-block mx-auto" />
                                </a>
                                <div className="card-body p-0">
                                    <small className="text-muted">Kurkura</small>
                                    <h6 className="card-title fw-bold">Masala Munch Crips Chipps</h6>
                                    <p className="card-text text-muted">40 g</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                                    <h6 className="fw-bold m-0">$10</h6>
                                    <a href="cart.html" className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-0">
                        <div className="col-6 border-end">
                            <div className="card bg-transparent border-0 p-3">
                                <a href="product-detail.html">
                                    <img src="img/lays-cream.png" alt className="img-fluid w-75 d-block mx-auto" />
                                </a>
                                <div className="card-body p-0">
                                    <small className="text-muted">Lay'S</small>
                                    <h6 className="card-title fw-bold">Lay's Amarican Cream & Onion</h6>
                                    <p className="card-text text-muted">73 g</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                                    <h6 className="fw-bold m-0">$30</h6>
                                    <a href="cart.html" className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card bg-transparent border-0 p-3">
                                <a href="product-detail.html">
                                    <img src="img/lays-masala.png" alt className="img-fluid w-75 d-block mx-auto" />
                                </a>
                                <div className="card-body p-0">
                                    <small className="text-muted">Lay'S</small>
                                    <h6 className="card-title fw-bold">Lay's India's Magic Masala Chips</h6>
                                    <p className="card-text text-muted">50 g</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                                    <h6 className="fw-bold m-0">$20</h6>
                                    <a href="cart.html" className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border-bottom g-0">
                        <div className="col-6 border-end">
                            <div className="card bg-transparent border-0 p-3">
                                <a href="product-detail.html">
                                    <img src="img/lays-potato.png" alt className="img-fluid w-75 d-block mx-auto" />
                                </a>
                                <div className="card-body p-0">
                                    <small className="text-muted">Lay'S</small>
                                    <h6 className="card-title fw-bold">Lay's Hot N Sweet Chiili Potato Chips</h6>
                                    <p className="card-text text-muted">50 g</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                                    <h6 className="fw-bold m-0">$20</h6>
                                    <a href="cart.html" className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card bg-transparent border-0 p-3">
                                <a href="product-detail.html">
                                    <img src="img/lays-hot-potato.png" alt className="img-fluid w-75 d-block mx-auto" />
                                </a>
                                <div className="card-body p-0">
                                    <small className="text-muted">Lay'S</small>
                                    <h6 className="card-title fw-bold">Lay's India's Magic Masala Chips</h6>
                                    <p className="card-text text-muted">100 g</p>
                                </div>
                                <div className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0">
                                    <h6 className="fw-bold m-0">$70</h6>
                                    <a href="cart.html" className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm">ADD</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="categories" tabIndex="-1" aria-labelledby="categoriesLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm modal-dialog-scrollable">
                    <div className="modal-content border-0 rounded-4 h-75">
                        <div className="modal-header border-0 px-4">
                            <div className="modal-title" id="categoriesLabel">
                                <h5 className="fw-bold text-black mb-1">Shop by categories</h5>
                                <p className="mb-0">24 categories</p>
                            </div>
                        </div>
                        <div className="modal-body border-top p-4">
                            <div className="row row-cols-3 gy-3">
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-1.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Hot Deals</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-2.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Fruits and Vegetables</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-3.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Atta, Rice and Dals</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-4.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Dry fruits and Masalas</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-5.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Dairy, Bread and Eggs</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-6.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Instant Food</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-7.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Chocklates & Desserts</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-8.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Munchies</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-9.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Cold Drinks and Juices</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-10.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Cereals and Breakfast</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-11.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Sauces and Spreads</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-12.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Tea, Coffee and More</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-13.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Biscuits</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-14.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Bath, Body and Hair</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-15.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Cleaning Essentials</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-16.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Beauty and Grooming</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-17.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Home and Kitchens</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-18.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Sationary and Electrical</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-19.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Hygiene and Wellness</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-20.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Baby Care</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-21.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">New Pet Supplies</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-22.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Paan Corner items</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-23.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Fresh Meat</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col">
                                    <a href="listing.html" className="link-dark">
                                        <div className="card bg-transparent border-0 text-center">
                                            <img src="img/category-24.png" alt className="card-img-top rounded-4 mb-2" />
                                            <div className="card-body p-0">
                                                <p className="card-title m-0">Foodmart Bakery items</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer