import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

function CustomPagination({ currentPage, totalPages, onPageChange }) {
  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber); // Call the prop function to handle page changes
  };

  // Generate page items based on totalPages
  const pageItems = [];
  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
        {i}
      </Pagination.Item>
    );
  }

  // Truncate page items if too many (customize thresholds as needed)
  const maxVisiblePages = 5; // Adjust as needed
  const visiblePages =
    totalPages <= maxVisiblePages ? pageItems : [
      ...pageItems.slice(0, 2),
      <Pagination.Ellipsis key="ellipsis" />,
      ...pageItems.slice(-2),
    ];

  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
      {visiblePages}
      <Pagination.Ellipsis key="ellipsis2" />
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  );
}

export default CustomPagination;
