import React, { useState } from "react";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CustomerDashboardSlider from "../Customer/Dashboard/CategoryCorousel/DashboardCourousel";
import ProductCorousel from "../Customer/Dashboard/ProductCorousel/ProductCorousel";
function Home() {
  return (

    <>
    <Header/>
      <div>
        <div className="bg-light">
          <div className="d-flex align-items-center justify-content-between p-3">
            <h5 className="fw-bold text-black mb-0">
              Great deals on top picks
            </h5>
            <span className="text-primary">
              View all<i className="icofont-rounded-right"></i>
            </span>
          </div>
          <CustomerDashboardSlider></CustomerDashboardSlider>
        </div>

        <div className="pt-3">
          <div className="d-flex align-items-center justify-content-between px-3 pb-3">
            <h5 className="fw-bold text-black mb-0">
              Great deals on top picks
            </h5>
            <span className="text-primary">
              View all<i className="icofont-rounded-right"></i>
            </span>
          </div>
          <div className="top-picks">
            <ProductCorousel></ProductCorousel>
          </div>
          <div className="p-3">
            <span
              href="javascript:void(0)"
              className="bg-success shadow text-white rounded-4 d-flex align-items-center px-3 py-2"
            >
              {" "}
              Explore{" "}
              <span className="bg-primary px-2 py-1 rounded-2 small text-uppercase fw-bold text-white m-2">
                Mega Savings
              </span>{" "}
              Store
              <i className="bi bi-arrow-right text-warning fs-5 ms-auto"></i>
            </span>
          </div>
        </div>

        <div className="bg-warning">
          <h5 className="fw-bold text-black mb-0 p-3">
            Save more with coupons
          </h5>
          <div>
            <div className="coupons">
              <div className="coupons-item">
                <div className="link-dark pb-3 ps-3">
                  <div className="d-flex align-items-center gap-3 shadow-sm rounded-4 p-3 bg-white">
                    <i className="icofont-sale-discount icofont-2x text-success"></i>
                    <div>
                      <h6 className="fw-bold osahan-mb-1">Flat $100 off</h6>
                      <p className="small text-muted text-uppercase mb-0">
                        Use Helloim | on orders above $400
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupons-item">
                <div className="link-dark pb-3 ps-3">
                  <div className="d-flex align-items-center gap-3 shadow-sm rounded-4 p-3 bg-white">
                    <i className="icofont-sale-discount icofont-2x text-success"></i>
                    <div>
                      <h6 className="fw-bold osahan-mb-1">Flat $100 off</h6>
                      <p className="small text-muted text-uppercase mb-0">
                        Use Savebig | on orders above $999
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupons-item">
                <div className="link-dark pb-3 ps-3">
                  <div className="d-flex align-items-center gap-3 shadow-sm rounded-4 p-3 bg-white">
                    <i className="icofont-sale-discount icofont-2x text-success"></i>
                    <div>
                      <h6 className="fw-bold osahan-mb-1">15% off upto $124</h6>
                      <p className="small text-muted text-uppercase mb-0">
                        Use Citi125 | orders above $750
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupons-item">
                <div className="link-dark pb-3 ps-3">
                  <div className="d-flex align-items-center gap-3 shadow-sm rounded-4 p-3 bg-white">
                    <i className="icofont-sale-discount icofont-2x text-success"></i>
                    <div>
                      <h6 className="fw-bold osahan-mb-1">10% off upto $150</h6>
                      <p className="small text-muted text-uppercase mb-0">
                        Use hdfc150 | orders above $1250
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupons-item">
                <div className="link-dark pb-3 ps-3">
                  <div className="d-flex align-items-center gap-3 shadow-sm rounded-4 p-3 bg-white">
                    <i className="icofont-sale-discount icofont-2x text-success"></i>
                    <div>
                      <h6 className="fw-bold osahan-mb-1">Flat $150 off</h6>
                      <p className="small text-muted text-uppercase mb-0">
                        Use federalim | orders above $499
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3 bg-light">
          <div className="rounded-4 ps-4 pt-4 shadow-sm d-flex gap-1 align-items-center bg-warning bg-gradient justify-content-between">
            <div className="pb-4">
              <h1 className="fw-bolder text-black display-5 mb-1">50% OFF</h1>
              <p className="text-dark">
                Special Offer: Get 50% Cashback +{" "}
                <span className="text-success">
                  <i className="bi bi-basket"></i> Free Delivery
                </span>
                <b className="bg-primary px-1 rounded-1 small text-uppercase fw-bold text-white mt-1 d-inline-block">
                  New Users
                </b>
              </p>
              <a
                href="listing.html"
                className="btn btn-light text-success fw-bold rounded-3 shadow-sm btn-sm border-0"
              >
                SHOP NOW
              </a>
            </div>
            <img
              src="img/banner1.png"
              alt
              className="img-fluid mt-auto osahan-offer-banner"
            />
          </div>
        </div>
      </div>
      <div className="btn-category">
        <a
          href="javascript:void(0)"
          className="btn btn-primary rounded-circle shadow-sm icon-lg"
        >
          <i className="icofont-food-basket icofont-3x text-white"></i>
        </a>
      </div>
      <Footer/>
    </>
  );
}

export default Home;
