import React, { useState, useEffect, useRef } from "react";
import { Card, Table, Form, Button, Container, Row, Col, CardHeader, CardBody, CardFooter } from "react-bootstrap";
import Header from "../../../components/Header/Header";
import requestApplicationJson from "../../../utils/fetch";
import sendAppropriateSelectOptionsSingleSelect from "../../../utils/Entities/sendAppropriateSelectOptionsSingleSelect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify"; // 1. Import toast from react-toastify
import Select from "react-select";
import { Pencil } from "react-bootstrap-icons";
import { baseUrl } from "../../../utils/server";
import CustomPagination from "../../Entities/Pagination";

import "./product.css";
import ChangeInStockModel from "./Models/ChangeInStockModel";
import ProductImageModel from "./Models/ProductImageModel";
function AddProducts() {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [categoryObj, setCategoryObj] = useState({});
  const [brandsOptions, setbrandsOptions] = useState([]);
  const [TotalPages, setTotalPages] = useState(0);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [brandObj, setbrandObj] = useState({});
  const [productTableData, setproductTableData] = useState([]);
  const [pageLength, setPageLength] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [stockModalShow, setStockModalShow] = useState(false);
  const [ImageModalShow, setImageModalShow] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [unitOptions, setunitsOptions] = useState([]);
  const [unitObj, setunitObj] = useState({});
  const [currentProductId, setCurrentProductId] = useState(0);

  const [productRecord, setProductRecord] = useState({});
  const [productRecordImage, setProductRecordImage] = useState([]);
  const userSession = useSelector((state) => state.auth);
  const firstEmptyFieldRef = useRef(null);
  const nameRef = useRef(null);
  const categoryRef = useRef(null);
  const scategoryRef = useRef(null);
  const mrpRef = useRef(null);
  const spriceRef = useRef(null);
  const unitRef = useRef(null);
  const QuantityRef = useRef(null);
  const mfgdetRef = useRef(null);
  const BrandRef = useRef(null);
  const DescriptionRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    category_id: '',
    sub_category_id: '',
    mrp: '',
    selling_price: '',
    unit: '',
    quantity: '',
    mfg_details: '',
    brand: '',
    description: '',
    product_id: null
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    // If the input field is MRP, apply custom validation
    if (name === 'mrp' || name === 'selling_price') {
      // Remove non-numeric characters except decimal point
      const sanitizedValue = value.replace(/[^\d.]/g, '');

      // Split the value into integer and decimal parts
      let [integerPart, decimalPart] = sanitizedValue.split('.');

      // Trim leading zeros from the integer part
      integerPart = integerPart.replace(/^0+/, '');

      // If decimal part is present and has more than two digits, truncate it
      if (decimalPart && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      } else if (decimalPart && decimalPart.length === 1) {
        // If decimal part has only one digit, append a zero
        decimalPart += '0';
      } else if (!decimalPart) {
        // If decimal part is not present, pad it with zeros
        decimalPart = '00';
      }

      // Combine integer and decimal parts with a decimal point
      const formattedValue = integerPart + (decimalPart ? '.' + decimalPart : '');

      // Update the form data with the formatted value
      setFormData(prevState => ({
        ...prevState,
        [name]: formattedValue
      }));
    }
    else if (name === 'quantity') {
      // Remove non-numeric characters
      const sanitizedValue = value.replace(/\D/g, '');
      // Update the form data with the sanitized value
      setFormData(prevState => ({
        ...prevState,
        [name]: sanitizedValue
      }));
    } else {
      // For other input fields, update the form data directly without any modifications
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleActivateDeactivate = async (tableName, curStatus, record_id) => {
    let activateDeactivatePayload = { record_meta: tableName, record_id: record_id, status: !Boolean(curStatus) };
    let response = await requestApplicationJson(
      "/admin/AdminCommon/activateDeactivateRecord",
      "POST",
      activateDeactivatePayload,
      userSession
    );
    if (response.status) {
      toast.success(response.data);
      handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
    } else {
      toast.error(response.data);
    }
  };
  const handleTablePaginationAndSearch = async (pageNumber, pageLength, search) => {
    let payload = { page: pageNumber, pagelength: pageLength, search: search, status: 0 };
    let apiResponse = await requestApplicationJson(
      "/admin/product/getProductDetailsAdminAPIPagination",
      "POST",
      payload,
      userSession
    );
    if (apiResponse.status) {
      setproductTableData(apiResponse.data.records);
      SetTotalRecords(apiResponse.data.total);
      setTotalPages(apiResponse.data.total / pageLength > 1 ? Math.ceil(apiResponse.data.total / pageLength) : 1);
    }
  };
  const setCategorizations = (e) => {
    let cate_payload = { page: 1, pagelength: 10, search: e };
    requestApplicationJson("/admin/productAttr/getCategories", "POST", cate_payload, userSession).then((categoriesRes) => {
      let new_cat = sendAppropriateSelectOptionsSingleSelect(categoriesRes.data.records, categoryObj);
      setCategoriesOptions(new_cat.map((item) => ({ value: item.id, label: item.name })));
    });
  };
  const getProductAllDetailsAPI = async (pid) => {
    let product_payload = { "product_id": pid }
    let product_data = await requestApplicationJson("/admin/product/adminGetOneProductAllDetails", "POST", product_payload, userSession);
    return product_data?.data;
  }
  const getBrandlist = (e) => {
    let cate_payload = { page: 1, pagelength: 10, search: e };
    requestApplicationJson("/admin/productAttr/getBrandAdmin", "POST", cate_payload, userSession).then((BrandResults) => {
      let new_brands = sendAppropriateSelectOptionsSingleSelect(BrandResults.data.records, brandObj);
      setbrandsOptions(new_brands.map((item) => ({ value: item.id, label: item.brand_name })));
    });
  };
  const getSubcategory = (e) => {
    let cate_payload = { page: 1, pagelength: 10, search: "", category_id: e.value };
    requestApplicationJson("/admin/productAttr/getSubCategoryForCategoryIDHandler", "POST", cate_payload, userSession)
      .then((response) => {
        const subcategoryResponse = response.data.records;
        if (Array.isArray(subcategoryResponse)) {
          const subcategoryOptions = subcategoryResponse.map(subcategory => ({
            value: subcategory.id,
            label: subcategory.name
          }));
          setSubcategories(subcategoryOptions);
        } else {
          setSubcategories([]);
        }
      })
      .catch(error => {
        setSubcategories([]);
      });
  };

  const getAdminUnit = (e) => {
    let cate_payload = { page: 1, pagelength: 10, search: e };
    requestApplicationJson("/admin/productAttr/getUnitAdmin", "POST", cate_payload, userSession).then((UnitResults) => {
      let new_unit = sendAppropriateSelectOptionsSingleSelect(UnitResults.data.records, unitObj);
      setunitsOptions(new_unit.map((item) => ({ value: item.id, label: item.unit })));
    })
  }
  useEffect(() => {
    handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
    setCategorizations("");
    getBrandlist("");
    getAdminUnit("");
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  // Function to reset the Select components
  const resetSelectComponents = () => {
    setCategoryObj({});
    setbrandObj({});
    setSelectedSubcategory(null);
    setunitObj({});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation
    let firstEmptyField = null;
    for (const field in formData) {
      if (!formData[field]) {
        firstEmptyField = field;
        break;
      }
    }

    if (firstEmptyField && firstEmptyField !== 'product_id') {
      // Get the reference of the first empty field
      let emptyFieldRef = null;
      let msg = "";
      switch (firstEmptyField) {
        case "name":
          emptyFieldRef = nameRef;
          msg = "Product Name";
          break;
        case "category_id":
          emptyFieldRef = categoryRef;
          msg = "Category";
          break;
        case "sub_category_id":
          emptyFieldRef = scategoryRef;
          msg = "Sub Category";
          break;
        case "mrp":
          emptyFieldRef = mrpRef;
          msg = "Maximum Retail Price";
          break;
        case "selling_price":
          emptyFieldRef = spriceRef;
          msg = "Selling Price";
          break;
        case "unit":
          emptyFieldRef = unitRef;
          msg = "Unit";
          break;
        case "quantity":
          emptyFieldRef = QuantityRef;
          msg = "Quantity";
          break;
        case "mfg_details":
          emptyFieldRef = mfgdetRef;
          msg = "MFG. Details";
          break;
        case "brand":
          emptyFieldRef = BrandRef;
          msg = "Brand";
          break;
        case "description":
          emptyFieldRef = DescriptionRef;
          msg = "Description";
          break;
        default:
          break;
      }

      // Focus on the first empty field
      if (emptyFieldRef && emptyFieldRef.current) {
        emptyFieldRef.current.focus();
      }
      toast.error(`Please fill in ${msg}.`);
      return;
    }
    let hit_url = "";
    if (formData.product_id === null) {
      delete formData.product_id;
      hit_url = "/admin/product/addProductAdmin";
    }
    else {
      hit_url = "/admin/product/editProductAdmin";
    }
    let Response = await requestApplicationJson(hit_url, "POST", formData, userSession);
    if (Response.status) {
      handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
      toast.success(Response.data)
      //     // Reset form after successful submission if needed
      setFormData({
        name: '',
        category_id: '',
        sub_category_id: '',
        mrp: '',
        selling_price: '',
        unit: '',
        quantity: '',
        mfg_details: '',
        brand: '',
        description: ''
      });
      resetSelectComponents(); // Reset Select components
    } else {
      toast.error(Response.data)
    }
  };

  // Function to fetch product details by ID
  // Function to fetch product details by ID
  const handleEditButtonClick = async (productData) => {
    setFormData({
      name: productData.pname,
      category_id: productData.pCategoryId, // Set appropriate category value
      sub_category_id: productData.sub_category_id, // Set appropriate subcategory value
      mrp: productData.mrp,
      selling_price: productData.selling_price,
      unit: productData.unit,
      quantity: productData.quantity,
      mfg_details: productData.mfg_details,
      brand: productData.brand,
      description: productData.description,
      product_id: productData.id
    });
    // Auto-select category
    const selectedCategory = categoriesOptions.find(option => option.value === productData.pCategoryId);
    setCategoryObj(selectedCategory);

    // Auto-select subcategory
    const selectedSubcategory = subcategories.find(option => option.value === productData.sub_category_id);
    setSelectedSubcategory(selectedSubcategory);

    // Auto-select unit
    const selectedUnit = unitOptions.find(option => option.value === productData.unit);
    setunitObj(selectedUnit);

    // Auto-select brand
    const selectedBrand = brandsOptions.find(option => option.value === productData.brand);
    setbrandObj(selectedBrand);

    // Fetch subcategories based on the selected category
    getSubcategory(selectedCategory);
  };





  return (
    <>
      <Header />
      <Container>
        <Form onSubmit={handleSubmit}>
          <Card className="mt-4">
            <CardHeader className="bg-primary text-white">
              <h6>Add Product</h6>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Name</h6>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="me-2 pinputs"
                    autoComplete="off"
                    ref={nameRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Category</h6>
                  </label>
                  <Select
                    options={categoriesOptions}
                    defaultValue={categoryObj}
                    value={categoryObj}
                    isSearchable={true}
                    onInputChange={setCategorizations}
                    onChange={(value) => {
                      setCategoryObj(value);
                      getSubcategory(value);
                      setFormData(prevState => ({
                        ...prevState,
                        category_id: value ? value.value : '' // Ensure to access the value property of the selected option
                      }));
                    }}
                    className="me-2 pinputs"
                    name="category"
                    ref={categoryRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Sub Category</h6>
                  </label>
                  <Select
                    value={selectedSubcategory}
                    onChange={(value) => {
                      setSelectedSubcategory(value);
                      setFormData(prevState => ({
                        ...prevState,
                        sub_category_id: value ? value.value : '' // Ensure to access the value property of the selected option
                      }));
                    }}
                    options={subcategories}
                    className="me-2 pinputs"
                    name="subcategory"
                    ref={scategoryRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0"> MRP</h6>
                  </label>
                  <input
                    type="text"
                    name="mrp"
                    value={formData.mrp}
                    onChange={handleChange}
                    autoComplete="off"
                    className="me-2 pinputs"
                    ref={mrpRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0"> Selling Price</h6>
                  </label>
                  <input
                    type="text"
                    name="selling_price"
                    value={formData.selling_price}
                    onChange={handleChange}
                    autoComplete="off"
                    className="me-2 pinputs"
                    ref={spriceRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Unit</h6>
                  </label>
                  <Select
                    options={unitOptions}
                    defaultValue={unitObj}
                    value={unitObj}
                    isSearchable={true}
                    onInputChange={getAdminUnit}
                    onChange={(value) => {
                      setunitObj(value);
                      setFormData(prevState => ({
                        ...prevState,
                        unit: value ? value.value : '' // Ensure to access the value property of the selected option
                      }));
                    }}
                    className="me-2 pinputs"
                    name="unit"
                    ref={unitRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Quantity</h6>
                  </label>
                  <input
                    type="text"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleChange}
                    autoComplete="off"
                    className="me-2 pinputs"
                    ref={QuantityRef}
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">MFG. Details</h6>
                  </label>
                  <input
                    type="text"
                    name="mfg_details"
                    value={formData.mfg_details}
                    onChange={handleChange}
                    ref={mfgdetRef}
                    autoComplete="off"
                    className="me-2 pinputs"
                  />
                </Col>
                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Brand</h6>
                  </label>
                  <Select
                    options={brandsOptions}
                    defaultValue={brandObj}
                    value={brandObj}
                    isSearchable={true}
                    onInputChange={getBrandlist}
                    onChange={(value) => {
                      setbrandObj(value);
                      setFormData(prevState => ({
                        ...prevState,
                        brand: value ? value.value : '' // Ensure to access the value property of the selected option
                      }));
                    }}
                    className="me-2 pinputs"
                    name="brand"
                    ref={BrandRef}
                  />
                </Col>

                <Col md={6}>
                  <label>
                    <h6 className="fw-bold text-dark mb-0">Description</h6>
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    ref={DescriptionRef}
                    className="me-2 pinputs"
                    autoComplete="off"
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-center">
              <Button variant="primary me-2" type="submit">
                Submit
              </Button>
              <Button variant="danger" type="button">
                cancel
              </Button>
            </CardFooter>
          </Card>
        </Form>
        <Row>
          <Col md={12}>
            <Card className="mt-4">
              <CardHeader className="bg-primary text-white">
                <h6>Product Details</h6>
              </CardHeader>
              <Card.Body>
                <Card>
                  <input
                    className="form-control"
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                      setCurrentPage(1);
                      handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
                    }}
                    placeholder="Search Product"
                  ></input>
                </Card>
                <Table striped bordered hover className="mt-4">
                  <thead>
                    <tr>
                      <th className="text-center">Index</th>
                      <th className="text-center">Image</th>
                      <th className="text-center">Product Name</th>
                      <th className="text-center">Category</th>
                      <th className="text-center">Sub Category</th>
                      <th className="text-center">MRP</th>
                      <th className="text-center">Selling Price</th>
                      <th className="text-center">Unit</th>
                      <th className="text-center">Stocks Left</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productTableData?.map((record, index_pp) => {
                      return (
                        <tr key={record.id}>
                          <td>{(currentPage === 1 ? 0 : currentPage * pageLength) + ++index_pp}</td>
                          <td className="text-center">
                            <img
                              alt="Product"
                              className="product-list-image-class"
                              onClick={async () => {
                                setProductRecord(record);
                                setImageModalShow(true);
                              }}
                              src={record.pImage ? baseUrl + "/public/assets/products/" + record.pImage : baseUrl + "/" + "public/assets/default.png"}
                            ></img>
                          </td>
                          <td>{record.pname}</td>
                          <td>{record.pCategory}</td>
                          <td>{record.scName}</td>
                          <td>{record.mrp}</td>
                          <td>{record.selling_price}</td>
                          <td>{record.unit_name}</td>
                          <td className="stock-change-td">
                            <span
                              onClick={(e) => {
                                setStockModalShow(true);
                                setProductRecord(record);
                              }}
                              className="stock-change-span"
                            >
                              {record.stock}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-warning"
                              onClick={() => handleEditButtonClick(record)}
                            >
                              <Pencil></Pencil>
                            </button>
                            <button
                              onClick={() => {
                                handleActivateDeactivate("cHJvZHVjdHNfbWFzdGVy", record.status, record.id);
                              }}
                              style={{ marginLeft: "10px" }}
                              className={record.status === 1 ? "ml-1 badge bg-danger" : "badge bg-success"}
                            >
                              {record.status === 1 ? "Deactivate" : "Activate"}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CustomPagination
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    setCurrentPage(page);
                    handleTablePaginationAndSearch(page, pageLength, searchKeyword);
                  }}
                  totalPages={TotalPages}
                ></CustomPagination>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ChangeInStockModel
        show={stockModalShow}
        onHide={() => setStockModalShow(false)}
        productRecord={productRecord}
        tableRefresher={() => {
          handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
        }}
      />
      <ProductImageModel
        show={ImageModalShow}
        onHide={() => setImageModalShow(false)}
        productRecord={productRecord}
        tableRefresher={() => {
          handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
        }}
        imageRecordChanger={setProductRecord}
      />
    </>
  );
}

export default AddProducts;
