import React, { useEffect, useState } from "react";
import requestApplicationJson from "../../../../utils/fetch";
import { useSelector } from "react-redux";
import { toast } from "react-toastify"; // 1. Import toast from react-toastify
import Table from "react-bootstrap/Table";
import { Pencil, Plus } from "react-bootstrap-icons";
import CustomPagination from "../../../Entities/Pagination";
import Select from "react-select";
import sendAppropriateSelectOptionsSingleSelect from "../../../../utils/Entities/sendAppropriateSelectOptionsSingleSelect";
function ProductSubCategory() {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, SetTotalRecords] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [TotalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [addDialogClicked, setAddDialogClicked] = useState(false);
  const [subCategoryName, setsCategoryName] = useState("");
  const [categoryObj, setCategoryObj] = useState({});
  const [subCategoryId, setsCategoryId] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const userSession = useSelector((state) => state.auth);
  const handleActivateDeactivate = async (tableName, curStatus, record_id) => {
    let activateDeactivatePayload = { record_meta: tableName, record_id: record_id, status: !Boolean(curStatus) };
    let response = await requestApplicationJson(
      "/admin/AdminCommon/activateDeactivateRecord",
      "POST",
      activateDeactivatePayload,
      userSession
    );
    if (response.status) {
      toast.success(response.data);
      handleTablePaginationAndSearch(currentPage, pageLength, searchKeyword);
    } else {
      toast.error(response.data);
    }
  };
  const handleEditButtonClick = (record) => {
    setCategorizations(null);
    setCategoryObj({ id: record.product_categories_obj?.id, label: record.product_categories_obj?.name });
    setsCategoryId(record.id);
    setsCategoryName(record.name);
    setAddDialogClicked(true);
  };
  const handleTablePaginationAndSearch = async (pageNumber, pageLength, search) => {
    let payload = { page: pageNumber, pagelength: pageLength, search: search, status: 0 };
    let apiResponse = await requestApplicationJson("/admin/productAttr/getSubCategories", "POST", payload, userSession);
    if (apiResponse.status) {
      setTableData(apiResponse.data.records);
      SetTotalRecords(apiResponse.data.total);
      setTotalPages(apiResponse.data.total / pageLength > 1 ? Math.ceil(apiResponse.data.total / pageLength) : 1);
    }
  };
  const setCategorizations = (e) => {
    let cate_payload = { page: 1, pagelength: 10, search: e };
    requestApplicationJson("/admin/productAttr/getCategories", "POST", cate_payload, userSession).then((categoriesRes) => {
      let new_cat = sendAppropriateSelectOptionsSingleSelect(categoriesRes.data.records, categoryObj);
      setCategoriesOptions(new_cat.map((item) => ({ value: item.id, label: item.name })));
    });
  };
  useEffect(() => {
    handleTablePaginationAndSearch(currentPage, pageLength, "");
  }, []);

  const handleSaveRecord = async () => {
    let payload = {};
    let categoryUrl = "";
    if (subCategoryId !== null) {
      payload = { sub_category_name: subCategoryName, category_id: categoryObj.value, sub_category_id: subCategoryId };
      categoryUrl = "/admin/productAttr/updateSubCategory";
    } else {
      payload = { sub_category_name: subCategoryName, category_id: categoryObj.value };
      categoryUrl = "/admin/productAttr/createSubCategory";
    }
    let addEditResponse = await requestApplicationJson(categoryUrl, "POST", payload, userSession);
    if (addEditResponse.status) {
      setCategoryObj({});
      setAddDialogClicked(false);
      toast.success(addEditResponse.data.message);
      handleTablePaginationAndSearch(currentPage, pageLength, "");
      setsCategoryName("");
      setsCategoryId(null);
      toast.success(addEditResponse.data);
    } else {
      toast.error(addEditResponse.data.message);
    }
  };
  return (
    <div className="col-md-12">
      <div className="row">
        {addDialogClicked && (
          <div className="col-md-12 mb-3">
            <div className="row">
              <div className="col-md-6">
                <label>Select Category Name</label>
                <Select
                  options={categoriesOptions}
                  defaultValue={categoryObj}
                  value={categoryObj}
                  isSearchable={true}
                  onInputChange={setCategorizations}
                  onChange={(value) => {
                    setCategoryObj(value);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label>Enter Sub-Category Name</label>
                <input
                  className="form-control"
                  onChange={(e) => {
                    setsCategoryName(e.target.value);
                  }}
                  value={subCategoryName}
                  placeholder="Sub-Category name"
                  maxLength={100}
                ></input>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    if (subCategoryName !== "" && categoryObj.length !== 0) {
                      handleSaveRecord();
                    } else {
                      toast.error("Category name Can't be empty.");
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12 table-search-and-add-button-class">
          <input
            placeholder="Search With Sub-Category name"
            className="form-control"
            onChange={(e) => {
              setSearchKeyword(e.target.value);
              if (e.target.value.length > 3 || e.target.value == "") {
                handleTablePaginationAndSearch(currentPage, pageLength, e.target.value);
              }
            }}
          ></input>
          <button
            className="btn btn-info"
            onClick={() => {
              setCategorizations();
              setAddDialogClicked(!addDialogClicked);
            }}
          >
            <Plus></Plus>
          </button>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Sub-Category Name</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((record) => {
            return (
              <tr key={record.id}>
                <td>{record.id}</td>
                <td>{record.name}</td>
                <td>{record.product_categories_obj?.name}</td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => {
                      handleEditButtonClick(record);
                    }}
                  >
                    <Pencil></Pencil>
                  </button>
                  <button
                    onClick={() => {
                      handleActivateDeactivate("cHJvZHVjdF9zdWJfY2F0ZWdvcmllcw==", record.status, record.id);
                    }}
                    style={{ marginLeft: "10px" }}
                    className={record.status === 1 ? "badge bg-danger" : "badge bg-success"}
                  >
                    {record.status === 1 ? "Deactivate" : "Activate"}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="row">
        <div className="col-md-12">
          <CustomPagination
            currentPage={currentPage}
            onPageChange={(page) => {
              setCurrentPage(page);
              handleTablePaginationAndSearch(page, pageLength, searchKeyword);
            }}
            totalPages={TotalPages}
          ></CustomPagination>
        </div>
      </div>
    </div>
  );
}
export default ProductSubCategory;
