import { Button, Form, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./ProductModel.css";
import requestApplicationJson, { requestApplicationFormData } from "../../../../utils/fetch";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ProductDraggableImage from "../ProductDraggableImage";

function ProductImageModel(props) {
  const [productImagerecord, setProductImagerecord] = useState([]);
  const [serverFileOrder, setServerFileOrder] = useState("");
  const [orderChanged, setOrderChanged] = useState(false);
  const userSession = useSelector((state) => state.auth);
  const getProductAllImagesAPI = async (pid) => {
    let product_payload = { product_id: pid };
    let product_data = await requestApplicationJson("/admin/product/getProductImages", "POST", product_payload, userSession);
    return product_data?.data;
  };
  const handleImageDeleteAPI = async (item) => {
    let delete_p = { product_image_id: item.id };
    let delete_res = await requestApplicationJson("/admin/product/deleteProductImage", "POST", delete_p, userSession);
    if (delete_res.status) {
      toast.success("Image Deleted.");
      setProductImagerecord(delete_res.data);
    } else {
      toast.error(delete_res.data.message);
    }
  };
  useEffect(() => {
    setServerFileOrder(JSON.stringify(productImagerecord));
  }, [productImagerecord]);
  useEffect(() => {
    if (props.show) {
      getProductAllImagesAPI(props.productRecord.id).then((dddd) => {
        setProductImagerecord(dddd);
      });
      setOrderChanged(false);
    }
  }, [props]);
  const onDragEnd = (result) => {
    // Reorder the items based on drag result
    if (!result.destination) return;

    const newItems = Array.from(productImagerecord);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setOrderChanged(serverFileOrder !== JSON.stringify(newItems));
    setProductImagerecord(newItems);
  };
  const handleChangeInOrder = async () => {
    let image_ids = productImagerecord.map((item) => item.id);
    let order_payload = { product_id: props.productRecord.id, product_images_id_series: image_ids };
    let order_res = await requestApplicationJson("/admin/product/swapImageForProduct", "POST", order_payload, userSession);
    if (order_res.status) {
      toast.success("Swapped Images");
      setProductImagerecord(order_res.data);
      setOrderChanged(false)
    } else {
      toast.error("Failed To Swap");
    }
  };
  const handleMakeImagePrimary = async (img_id,pid)=>{
    let order_payload = {"product_id":pid,"image_id":img_id};
    let order_res = await requestApplicationJson("/admin/product/makeImageThePrimaryImageForProduct", "POST", order_payload, userSession);
    if (order_res.status) {
      toast.success("Image Marked as Primary");
      setProductImagerecord(order_res.data);
    } else {
      toast.error("Failed To Mark As Primary");
    }
    }
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Product's Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable type="group" droppableId={"ProductDraggableImage"} direction="horizontal">
              {(provided) => (
                <div style={{ display: "flex", overflow: "scroll" }} {...provided.droppableProps} ref={provided.innerRef}>
                  {productImagerecord?.map((item, index) => (
                    <Draggable
                      key={"Drag" + item.id.toString()}
                      id={"Drag" + item.id.toString()}
                      draggableId={"Drag" + item.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <ProductDraggableImage
                          refname={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          handleImageDeleteAPI={handleImageDeleteAPI}
                          handleMakeImagePrimary={handleMakeImagePrimary}
                          imageItem={item}
                          className="col-md-3 draggable-product-parent-div"
                        ></ProductDraggableImage>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {orderChanged && (
            <div className="col-md-12 save-order-button-div">
              <Button onClick={handleChangeInOrder} className="mt-1">
                Save Order
              </Button>
            </div>
          )}
          <div className="col-md-12 mt-4">
            <p>Add Image</p>
            <Form.Control
              onChange={async (e) => {
                console.log(e.target.files);
                let upload_api = new FormData();
                upload_api.append("product_id", props.productRecord.id);
                upload_api.append("p_image", e.target.files[0]);
                let upload_response = await requestApplicationFormData(
                  "/admin/product/uploadProductImage",
                  upload_api,
                  userSession
                );
                if (upload_response.status) {
                  e.target.value = "";
                  let new_images = await getProductAllImagesAPI(props.productRecord.id);
                  setProductImagerecord(new_images);
                  toast.success("Image Uploaded");
                } else {
                  toast.error("Failed to upload Image.");
                }
              }}
              type="file"
            ></Form.Control>
          </div>
          <div className="col-md-12 change-in-stock-div">
            <Button className="btn btn-danger" onClick={props.onHide}>
              CLose
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default ProductImageModel;
