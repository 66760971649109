import { baseUrl } from "./server";
/**
 *
 * @param {String} url
 * @param {String} method
 * @param {String} contentType
 * @param {String | Object} payload
 */
async function requestApplicationJson(
  url,
  APIMethod,
  payload,
  authSlice = null,
  contentType = "application/json"
) {
  try {
    let actual_payload =
      typeof payload == "string" ? payload : JSON.stringify(payload);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", contentType);
    if (authSlice?.tokenJWT) {
      myHeaders.append("Authorization", authSlice.tokenJWT);
    }
    let response = await fetch(baseUrl + url, {
      method: APIMethod,
      body: actual_payload,
      headers: myHeaders,
    });
    let response_text = await response.json();
    if (!response.status === 500) {
      console.log("API error", response);
    }
    return response_text;
  } catch (error) {
    console.log(error);
  }
}

export default requestApplicationJson;


/**
 *
 * @param {String} url
 * @param {FormData} payload
 * @param {Object} authSlice
 * @param {String} APIMethod
 */
export async function requestApplicationFormData(
  url,
  payload,
  authSlice = null,
  APIMethod="POST"
) {
  try {
    const myHeaders = new Headers();
    if (authSlice?.tokenJWT) {
      myHeaders.append("Authorization", authSlice.tokenJWT);
    }
    let response = await fetch(baseUrl + url, {
      method: APIMethod,
      body: payload,
      headers: myHeaders,
    });
    let response_text = await response.json();
    if (!response.status === 500) {
      console.log("API error", response);
    }
    return response_text;
  } catch (error) {
    console.log(error);
  }
}
