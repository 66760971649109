import React, { useState } from "react";
import requestApplicationJson from "../../../utils/fetch";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken,revertAuth } from "../../../sessions/docks/authSlice";
import { useNavigate } from "react-router-dom";
import Header from '../../../components/Header/Header';
function AdminHone() {
  const userSession = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
    <Header/>
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h1> Admin Logged In</h1>
        </div>
      </div>
    </div>
    </>
  );
}
export default AdminHone;
