import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home/Home';
import AdminLogin from './components/Admin/Login/AdminLogin';
import AdminHome from './components/Admin/Home/AdminHome';
import Login from './components/Entry/login';
import Register from './components/Entry/register';
import { persistStore } from 'redux-persist';
import store from './sessions/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import ProductSettings from './components/Admin/ProductSettings/ProductSettings';
import AddProducts from './components/Admin/Products/AddProducts';
const persistor = persistStore(store);
function App() {
  return (
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/login'>
            <Route index element={<Login/>}></Route>
          </Route>
          <Route path='/signUp'>
            <Route index element={<Register/>}></Route>
          </Route>
          <Route path='/adminPanel'>
            <Route index element={<AdminLogin/>}></Route>
            <Route path="/adminPanel/Home" element={<AdminHome></AdminHome>}></Route>
            <Route path='/adminPanel/ProductsSettings' element={<ProductSettings></ProductSettings>}></Route>
            <Route path='/adminPanel/AddProducts' element={<AddProducts></AddProducts>}></Route>

          </Route>
          <Route path="*" element={<div >404</div>} />
          </Routes>
        <ToastContainer />
    </BrowserRouter>
    </PersistGate>
    </Provider>
  );
}

export default App;
