import React from "react";
import Slider from "react-slick";

function CustomerDashboardSlider() {
  const items = [
    { id: 1, image: "img/category-1.png", title: "Foodmart Hot Deals" },
    { id: 2, image: "img/category-2.png", title: "Fruits and Vegetables" },
    { id: 3, image: "img/category-3.png", title: "Atta, Rice and Dals" },
    { id: 4, image: "img/category-4.png", title: "Dry fruits and Masalas" },
    { id: 5, image: "img/category-5.png", title: "Dairy, Bread and Eggs" },
    { id: 6, image: "img/category-6.png", title: "Foodmart Instant Food" },
    { id: 7, image: "img/category-7.png", title: "Chocklates and Desserts" },
    { id: 8, image: "img/category-8.png", title: "Foodmart Munchies" },
    { id: 9, image: "img/category-9.png", title: "Cold Drinks and Juices" },
    { id: 10, image: "img/category-10.png", title: "Cereals and Breakfast" },
    { id: 11, image: "img/category-11.png", title: "Sauces and Spreads" },
    { id: 12, image: "img/category-12.png", title: "Tea, Coffee and More" },
    { id: 13, image: "img/category-13.png", title: "Foodmart Biscuits" },
    { id: 14, image: "img/category-14.png", title: "Bath, Body and Hair" },
    { id: 15, image: "img/category-15.png", title: "Cleaning Essentials" },
    { id: 16, image: "img/category-16.png", title: "Beauty and Grooming" },
    { id: 17, image: "img/category-17.png", title: "Home and Kitchens" },
    { id: 18, image: "img/category-18.png", title: "Sationary and Electrical" },
    { id: 19, image: "img/category-19.png", title: "Hygiene and Wellness" },
    { id: 20, image: "img/category-20.png", title: "Foodmart Baby Care" },
    { id: 21, image: "img/category-21.png", title: "New Pet Supplies" },
    { id: 22, image: "img/category-22.png", title: "Paan Corner items" },
    { id: 23, image: "img/category-23.png", title: "Foodmart Fresh Meat" },
    { id: 24, image: "img/category-24.png", title: "Foodmart Bakery items" },
  ];
  const settings = {
    infinite: true,
    dots: false,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    className: "center",
    centerPadding: "60px",
    slidesToShow: 7,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {items.map((item) => (
        <div key={item.id} className="slide">
          <img src={item.image} alt={item.title} />
          <div style={{display:"flex",justifyContent:"center"}}>
            <span style={{ fontSize: "12px" }}>{item.title}</span>
          </div>
        </div>
      ))}
    </Slider>
  );
}

export default CustomerDashboardSlider;
