import React from "react";
import Slider from "react-slick";

function ProductCorousel() {
  const items = [
    { id: 1, image: "img/category-1.png", title: "Foodmart Hot Deals" },
    { id: 2, image: "img/category-2.png", title: "Fruits and Vegetables" },
    { id: 3, image: "img/category-3.png", title: "Atta, Rice and Dals" },
    { id: 4, image: "img/category-4.png", title: "Dry fruits and Masalas" },
    { id: 5, image: "img/category-5.png", title: "Dairy, Bread and Eggs" },
    { id: 6, image: "img/category-6.png", title: "Foodmart Instant Food" },
    { id: 7, image: "img/category-7.png", title: "Chocklates and Desserts" },
    { id: 8, image: "img/category-8.png", title: "Foodmart Munchies" },
    { id: 9, image: "img/category-9.png", title: "Cold Drinks and Juices" },
    { id: 10, image: "img/category-10.png", title: "Cereals and Breakfast" },
    { id: 11, image: "img/category-11.png", title: "Sauces and Spreads" },
    { id: 12, image: "img/category-12.png", title: "Tea, Coffee and More" },
    { id: 13, image: "img/category-13.png", title: "Foodmart Biscuits" },
    { id: 14, image: "img/category-14.png", title: "Bath, Body and Hair" },
    { id: 15, image: "img/category-15.png", title: "Cleaning Essentials" },
    { id: 16, image: "img/category-16.png", title: "Beauty and Grooming" },
    { id: 17, image: "img/category-17.png", title: "Home and Kitchens" },
    { id: 18, image: "img/category-18.png", title: "Sationary and Electrical" },
    { id: 19, image: "img/category-19.png", title: "Hygiene and Wellness" },
    { id: 20, image: "img/category-20.png", title: "Foodmart Baby Care" },
    { id: 21, image: "img/category-21.png", title: "New Pet Supplies" },
    { id: 22, image: "img/category-22.png", title: "Paan Corner items" },
    { id: 23, image: "img/category-23.png", title: "Foodmart Fresh Meat" },
    { id: 24, image: "img/category-24.png", title: "Foodmart Bakery items" },
  ];
  const settings = {
    infinite: true,
    dots: false,
    swipeToSlide: true,
    className: "center",
    centerPadding: "60px",
    slidesToShow: 4,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
  };

  return (
    <Slider {...settings}>
      {items.map((item) => (
        <div key={item.id} className="top-picks-item">
        <div className="card bg-transparent border-0 overflow-hidden h-100 ps-3">
          <a href="product-detail.html" className="link-dark">
            <div className="bg-light rounded-4 p-4 position-relative product-box">
              <img
                src="img/amul-butter.png"
                alt
                className="img-fluid h-100 d-block mx-auto"
              />
            </div>
          </a>
          <div
            className="bg-info position-absolute top-0 text-white osahan-badge text-center mx-3"
          >
            <b>2%</b>
            <br />
            OFF
          </div>
          <div className="card-body p-0">
            <small className="text-muted">Amul</small>
            <h6 className="card-title fw-bold">Pasteuised Butter Pack of 2</h6>
            <p className="card-text text-muted">100 g x 2</p>
          </div>
          <div
            className="card-footer bg-transparent border-0 d-flex align-items-center justify-content-between p-0"
          >
            <div>
              <del className="small text-muted">$108</del>
              <h6 className="fw-bold m-0">$106</h6>
            </div>
            <a
              href="cart.html"
              className="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm"
            >
              ADD
            </a>
          </div>
        </div>
      </div>
      ))}
    </Slider>
  );
}

export default ProductCorousel;
