import React, {  } from "react";
import { Image } from "react-bootstrap";
import { baseUrl } from "../../../utils/server";

import "./product.css";
function ProductDraggableImage(props) {
  return (
    <div ref={props.refname} {...props}>
      <span
      className="image-drag-drop-delete-icon"
        onClick={() => {
          props.handleImageDeleteAPI(props.imageItem);
        }}
      >
        X
      </span>
      <Image 
      onDoubleClick={()=>{
        props.handleMakeImagePrimary(props.imageItem.id,props.imageItem.pid);
      }}
      className={props.imageItem?.is_primary?"image-add-list-model primary":"image-add-list-model"} src={baseUrl + "/public/assets/products/" + props.imageItem?.path}></Image>
    </div>
  );
}
export default ProductDraggableImage;
