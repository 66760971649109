import React, { useState, useEffect } from 'react';
import '../../css/loader.css'; // Import the CSS styles

const Loader = ({ isLoading }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const body = document.querySelector('body');
    if (isLoading) {
      body.classList.add('body-overlay'); // Add class to body to dim it
    } else {
      body.classList.remove('body-overlay'); // Remove class to remove dim effect
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div class="loading">Loading</div>
      )}
    </>
  );
};

export default Loader;
