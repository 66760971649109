import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../css/login.css";
import condition from "../../utils/condition";
import requestApplicationJson from "../../utils/fetch";
import { useSelector, useDispatch } from "react-redux";
import { setAuthToken } from "../../sessions/docks/authSlice";
import { toast } from "react-toastify"; // 1. Import toast from react-toastify

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isCredLogin, setIsCredLogin] = useState(true);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const userSession = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSendOtp = async ()=>{
    if (
      condition.isValidEmail(username) ||
      condition.isValidIndianNumber(username)
    )
    {
      let payload = {"username":username};
      let sendOTPres = await requestApplicationJson("/auth/login","POST",payload);
      if(sendOTPres?.status){
        toast.success(sendOTPres.data);
        setShowOtpInput(true)
      }
      else{
        toast.error(sendOTPres.data);
      }
    }
    else{
      toast.error("Please enter valid username")
    }
  }
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const handleLoginMethodSwitch = (e) => {
    setIsCredLogin(!isCredLogin);
    setShowOtpInput(false)
  };
  const handleSubmitCred = async (e) => {
    e.preventDefault();
    if(isCredLogin)
    {
      if (
        condition.isValidEmail(username) ||
        condition.isValidIndianNumber(username)
      ) {
        let payload = { username: username, password: password,guest_id:userSession.guest_id };
        let loginData = await requestApplicationJson(
          "/auth/userLogin",
          "POST",
          payload
        );
        if (loginData.status) {
          let initial_slice = { ...userSession, ...loginData.data.user };
          initial_slice.tokenJWT = loginData.data.token;
          initial_slice.isLoggedIn = true;
          dispatch(setAuthToken(initial_slice));
          toast.success(loginData.data.message);
          navigate("/");
        } else {
          toast.error(loginData.data.message);
        }
      } else {
        alert("Please enter valid email/mobile");
      }
    }
    else if (!showOtpInput){
      await handleSendOtp();
    }
    else{
      let payload_otp_check = {"username":username,"otp":otp,guest_id:userSession.guest_id};
      let loginUsingOtp = await requestApplicationJson("/auth/checkForOtpAPI","POST",payload_otp_check);
      if(loginUsingOtp.status)
      {
        let initial_slice = { ...userSession, ...loginUsingOtp.data };
          initial_slice.tokenJWT = loginUsingOtp.data.token;
          initial_slice.isLoggedIn = true;
          dispatch(setAuthToken(initial_slice));
          toast.success(loginUsingOtp.data.message);
          navigate("/");
      }
      else {
        toast.error(loginUsingOtp.data.message);
      }
    }
  };

  return (
      <div className="screen">
        <div className="screen__content">
          <form className="login" onSubmit={handleSubmitCred}>
            <div className="login__field">
              <i className="login__icon fas fa-user"></i>
              <input
                type="text"
                className="login__input"
                placeholder="User name / Email"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            {isCredLogin ? (
              <div className="login__field">
                <i className="login__icon fas fa-lock"></i>
                <input
                  type="password"
                  className="login__input"
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
            ) : (
              showOtpInput?<div className="login__field">
              <i className="login__icon fas fa-lock"></i>
              <input
                type="text"
                className="login__input"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e)=>{
                  console.log(e.target.value);
                  setOtp(e.target.value)
                }}
              />
            </div>:
              <></>
            )}
            {isCredLogin ? (
              <button type="submit" className="button login__submit">
                <span className="button__text">Log In Now</span>
                <i className="button__icon fas fa-chevron-right"></i>
              </button>
            ) : (
              <>
                <button type="submit" className="button login__submit">
                  <span className="button__text">Send OTP</span>
                  <i className="button__icon fas fa-chevron-right"></i>
                </button>
              </>
            )}
          </form>

          {/* Create New Account Link */}
          <div className="create-account text-center w-100">
            <span>
              <p
                onClick={() => {
                  handleLoginMethodSwitch();
                }}
              >
                {isCredLogin ? "Login Using OTP" : "Login using Password"}
              </p>
            </span>
            <Link to="/signUp" className="create-account__link">
              Create New Account
            </Link>
          </div>
          <div className="social-login">
            <h3>log in via</h3>
            <div className="social-icons">
              <a href="javascript:void(0)" className="social-login__icon fab fa-instagram"></a>
              <a href="javascript:void(0)" className="social-login__icon fab fa-facebook"></a>
              <a href="javascript:void(0)" className="social-login__icon fab fa-twitter"></a>
            </div>
          </div>
        </div>
        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>
          <span className="screen__background__shape screen__background__shape3"></span>
          <span className="screen__background__shape screen__background__shape2"></span>
          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
  );
};

export default Login;
