/**
 * 
 * @param {Array<Object>} options 
 * @param {Object} option 
 * @returns {Array<Object>}
 */
function sendAppropriateSelectOptionsSingleSelect(options, option) {
  let isValueAvailable = false;

  options.forEach((element) => {
    if (element.id === option.id) {
      isValueAvailable = true;
    }
  });

  if (!isValueAvailable) {
    options.push(option);
  }
  return options;
}
export default sendAppropriateSelectOptionsSingleSelect;
