import {v4 as uuid} from 'uuid'
const authInitialState = {
  tokenJWT: null,
  user_id: null,
  email: null,
  user_type: null,
  isLoggedIn: false,
  first_name:"User",
  last_name:"",
  guest_id:uuid()
};

export default authInitialState;
