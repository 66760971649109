import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import "./ProductModel.css"
import requestApplicationJson from "../../../../utils/fetch";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ChangeInStockModel(props) {
    const [changeInState,setChangeInState] = useState(0);
    const [changeInStateDesc,setChangeInStateDesc] = useState("")
    const userSession = useSelector((state) => state.auth);

    const handleChangeInStock =async ()=>{
      let changeStockPayload ={"change_in_stock":parseInt(changeInState),"product_id":props.productRecord.id,"comment":changeInStateDesc};
      let changeStock = await requestApplicationJson("/admin/product/addProductsStockAdmin","POST",changeStockPayload,userSession);
      if(changeStock.status)
      {
        setChangeInStateDesc("");
        toast.success(changeStock.data);
        props.onHide();
        props.tableRefresher();
      }
      else{
        toast.error(changeStock.data);
      }
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Stock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-md-12">
                <p>Current Stock <span className="text-warning">{props.productRecord?.stock}</span> </p>
                <input type="number" value={changeInState} onChange={(e)=>{
                    setChangeInState(e.target.value);
                }} className="form-control" placeholder="Change In Stock"></input>
            </div>
            <div className="col-md-12">
                <p>Change Description</p>
                <textarea onChange={(e)=>{
                    setChangeInStateDesc(e.target.value);
                }} className="form-control" placeholder="Change In Stock Desc" maxLength={200}>{changeInStateDesc}</textarea>
            </div>
            <div className="col-md-12 change-in-stock-div">
                <Button className="btn btn-success" onClick={()=>{
                  if(changeInStateDesc!=="")
                  {
                    handleChangeInStock()
                  }
                  else{
                    toast.error("Change In stock Desc Can't be empty");
                  }
                  }}>Change</Button>
                <Button className="btn btn-danger" onClick={props.onHide}>Cancel</Button>
            </div>
        </div>
        </Modal.Body>
      </Modal>
    );
  }
  export default ChangeInStockModel;
